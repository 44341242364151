import React from "react";
import '../../Assets/Styles.css'

const FooterNew=()=>{
    return(
    <>
          <div className=" footer-copyright text-center" style={{padding:'1vw', bottom:"0", width:"100%", color:"#808080", flex: "0 0 50px", marginTop: "auto"}} >
          <center>
              Copyright &copy; {new Date().getFullYear()} <span>| FinSharp LLC "doing business as" DataPecan.com | Conceptualized by Stratevity LLC | All rights reserved </span>
          </center>
          
          </div>
    </>
    )
}
export default FooterNew