import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    // alert(1)
    // document.documentElement.scrollTo(0, 0);
    document.querySelector('body').scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
    // window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}