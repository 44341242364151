import React, {lazy, Suspense} from 'react'

const Products = lazy(() => import('../Components/Products/Products'));

const ServicePage = () => {
    return (
     <>
     <Suspense fallback={<div></div>}><Products/></Suspense> 
      </>
    );
  }
  
  export default ServicePage ;