import { React, useState } from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Logo from "../../Assets/logo.png";
import '../../Assets/Styles.css'

const Header = () => {
    const [expanded, setExpanded] = useState(false);

    return (
        <Navbar bg="light" expand="lg" expanded={expanded}>
            <LinkContainer to="">
                <Navbar.Brand className="headlogo">
                    <img src={Logo}
                        alt="Logo"
                        width='auto'
                        height='60vh'
                        className="medialogo"
                    />
                </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} aria-controls="" />
            <Navbar.Collapse id="" >
                <Nav className="navtext">
                    <LinkContainer to="/home" onClick={() => setExpanded(false)}>
                        <Nav.Link>Home</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/pecanprep" onClick={() => setExpanded(false)}>
                        <Nav.Link >Products</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/service" onClick={() => setExpanded(false)}>
                        <Nav.Link>Services</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/technology" onClick={() => setExpanded(false)}>
                        <Nav.Link >Technology</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/case-studies" onClick={() => setExpanded(false)}>
                        <Nav.Link >Case Studies</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/team" onClick={() => setExpanded(false)}>
                        <Nav.Link >Team</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/about" onClick={() => setExpanded(false)}>
                        <Nav.Link><Button style={{ borderRadius: "6px", padding: "10px", paddingLeft: '24px', paddingRight: '24px' }}>Get in Touch</Button></Nav.Link>
                    </LinkContainer>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default Header;