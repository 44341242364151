import React, {lazy, Suspense} from 'react'

const About = lazy(() => import('../Components/About/About'));

const ServicePage = () => {
    return (
     <>
     <Suspense fallback={<div></div>}><About/></Suspense> 
      </>
    );
  }
  
  export default ServicePage ;