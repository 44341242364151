import './App.css';
import { lazy, Suspense } from 'react'
import Home from './Pages/Home';
// import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import Service from './Pages/Service';
import Products from './Pages/Products';
import Team from './Pages/Team';
import About from './Pages/About';
import NotFoundPage from './Pages/NotFoundPage';

import Technology from './Pages/Technology';
import CaseStudies from './Pages/CaseStudies';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Footer from './Components/Footer/Footer';
import React, { useEffect, useState } from "react";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Rings } from 'react-loader-spinner'


function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return <div>{loading ?
    (<div style={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center' }}><Rings
      heigth="100"
      width="100"
      color='#4285f4'
      ariaLabel='loading'
    /></div>) :
    (<div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Router>
        <Header />
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/service" component={Service} />
          <Route exact path="/pecanprep" component={Products} />
          <Route exact path="/technology" component={Technology} />
          <Route exact path="/case-studies" component={CaseStudies} />
          <Route exact path="/team" component={Team} />
          <Route exact path="/about" component={About} />



          <Route component={NotFoundPage} />

        </Switch>
        <Footer />
      </Router>
    </div>)}
  </div>;
}

export default App;
