import React, {lazy, Suspense} from 'react'

const CaseStudies = lazy(() => import('../Components/CaseStudies/CaseStudies'));
// const Banner = lazy(() => import('../Components/OurStoryComponents/OurStoryBanner/OurStoryBanner'));

const ServicePage = () => {
    return (
     <>
     <Suspense fallback={<div></div>}><CaseStudies/></Suspense> 
      </>
    );
  }
  
  export default ServicePage ;