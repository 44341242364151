import React, {lazy, Suspense} from 'react'

const Service = lazy(() => import('../Components/Service/Service'));
// const Banner = lazy(() => import('../Components/OurStoryComponents/OurStoryBanner/OurStoryBanner'));

const ServicePage = () => {
    return (
     <>
     {/* <Suspense fallback={<div></div>}><Banner 
     headline="Our Services" 
     headtext="“When a senior member of the family needs assistance, it's natural for all
     the family members to jump in for help. However, some can't reach them
     in a time of need no matter how hard they try.”"/></Suspense> */}
     <Suspense fallback={<div></div>}><Service/></Suspense>
      </>
    );
  }
  
  export default ServicePage ;