import React, {lazy, Suspense} from 'react'

const NotFound = lazy(() => import('../Components/NotFound/NotFound'));

const ServicePage = () => {
    return (
     <>
     <Suspense fallback={<div></div>}><NotFound/></Suspense> 
      </>
    );
  }
  
  export default ServicePage ;