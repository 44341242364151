import React, {lazy, Suspense} from 'react'
const Home = lazy(() => import('../Components/Home/Home'));

const HomePage = () => {
    return (
     <>
        <Suspense fallback={<div></div>}><Home/></Suspense>
      </>
    );
  }
  
  export default HomePage;